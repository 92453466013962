import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useContext, useState, useEffect } from "react"
import styles from "./reviews.module.scss"
import r from "../../../services/Router"
import t from "../../../services/GetTranslationValue"
import ThemeContext from "../../../context/ThemeContext"
import Skeleton from '@material-ui/lab/Skeleton';
import { isLoggedIn, getUser } from "../../../services/auth";
import { fetchUserReviewsByUser } from "../../../api/client/userReviews"
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';

const Reviews = ({ locale, translations, profile, getUser, casinos }) => {
  let [loading, setLoading] = useState(true);
  let [userReviews, setUserReviews] = useState([])
  useEffect(() => {
    async function effect() {
      try {
        const res = await fetchUserReviewsByUser()
        setUserReviews(res.comments)
        setLoading(false);
      }
      catch (err) {
        console.log(err)
      }
    }
    effect()
  }, [])

  const getCasinoName = (extId) => {
    const casino = casinos.edges.find(x => x.node._id === extId)
    if (casino) 
      return <Link to={r.get(locale, translations, `casinoReview`, casino.node.name)}>
        {casino.node.name}
      </Link>

      return ""
  }

  return (
    <div>
      {isLoggedIn() && profile && profile.user && !loading ? (
        <div className={`${styles.reviews}`}>
          <div className={`${styles.reviewsWrapper}`}>
            {userReviews.length === 0 &&
              <div className={`${styles.reviewsWelcomeWrapper}`}>
                <p className={`${styles.welcomeTitle}`}>{t.get(translations, 'user-profile-no-reviews-main-title')}!</p>
                <div className={`${styles.welcomeContent}`}>
                  <p>{t.get(translations, 'user-profile-no-reviews-content')}</p>
                  <Link to={r.get(locale, translations, "home", "")} className={`${styles.dashboardLogo}`}>
                    <span>{t.get(translations, 'user-profile-find-casinos')}</span>
                  </Link>
                </div>
                <p className={`${styles.benefitsTitle}`}>{t.get(translations, 'user-profile-benefits-title')}: </p>
                <ul>
                  <li>{t.get(translations, 'user-profile-benefits-coin')}</li>
                  <li>{t.get(translations, 'user-profile-benefits-voice')}</li>
                  <li>{t.get(translations, 'user-profile-benefits-bonuses')}</li>
                  <li>{t.get(translations, 'user-profile-benefits-deals')}</li>
                  <li>{t.get(translations, 'user-profile-benefits-players')}</li>
                </ul>
              </div>
            }
            {userReviews.length !== 0 &&
              <div>
                <p className={`${styles.mainTitle}`}>{t.get(translations, 'user-profile-your-reviews')}:</p>
                {userReviews && userReviews.map(x => (
                  <div key={`comment-${x.id}`} className={`${styles.reviewItem}`}>
                    <p className={`${styles.reviewTitle}`}>
                      <MessageOutlinedIcon />
                      {x.commentTitle}
                    </p>
                    <p className={`${styles.reviewBody}`} dangerouslySetInnerHTML={{ __html: x.commentBody }}></p>
                    <p className={`${styles.reviewDate}`}>{x.createdAt.slice(0, 10)},</p>
                    <p className={`${styles.reviewCasino}`}>
                      {getCasinoName(x.extId)}
                    </p>
                  </div>
                ))}
              </div>
            }
          </div>
        </div>)
        :
        <div className={`${styles.reviews}`}>
          <div className={`${styles.reviewsWrapper} ${styles.userProfileSkeleton}`}>
            <Skeleton className={`${styles.skeletons}`} variant="text" animation="wave" height={50} width="100%" />
            <Skeleton className={`${styles.skeletons} ${styles.skeletonBody}`} variant="rect" width="100%" height={200} animation="wave" />
            <Skeleton className={`${styles.skeletons} ${styles.skeletonBody}`} variant="rect" width="100%" height={200} animation="wave" />
          </div>
        </div>
      }
    </div>
  )
}
export default Reviews
