import React, { useContext, useState, useEffect } from "react"
import { graphql, navigate, Link } from "gatsby"
import styles from "./my-account.module.scss"
import ThemeContext from "../../context/ThemeContext"
import t from '../../services/GetTranslationValue'
import r from "../../services/Router"
import localize from "../../components/localize"
import { localizeItem } from "../../graphql-assistant/createLocaleTextGetter"
import { isLoggedIn, getUser, logout } from "../../services/auth";
import { getLocaleAlias } from "../../graphql-assistant/createLocaleTextGetter"
import Divider from '@material-ui/core/Divider';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import CasinoOutlinedIcon from '@material-ui/icons/CasinoOutlined';
import BigWins from './big-wins/big-wins';
import Profile from './profile/profile';
import Reviews from './reviews/reviews';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const MyAccountPage = ({ location, data, pageContext, props }) => {
  let { locale } = pageContext
  let { translations, profile, setProfile } = useContext(ThemeContext);

  let gamesData = data.allSanityGame
  let casinosData = data.allSanityCasino

  const user = getUser()

  const [hideMenu, setHideMenu] = useState(true);

  const toggleMenu = () => {
    setHideMenu(!hideMenu);
  };

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate(`${getLocaleAlias(locale)}`)
    }
  }, [])

  const handleLogout = async () => {
    logout(() => {
      setProfile({})
      navigate(`/${getLocaleAlias(locale)}`)
    })
  }

  const handleClickAway = () => {
    setHideMenu(true);
  };

  const TopNav = ({locale, translations, profile}) => {
    return (
      <div className={`${styles.topNav}`}>
        {isLoggedIn() && profile && profile.user ? [
          <div className={`${styles.userIcon}`} onClick={() => swapPage(1)}>
            <PersonOutlineOutlinedIcon/>
            <span>{profile.user.username}</span>
          </div>,
          <div className={`${styles.points}`}>
            <label>{t.get(translations, 'user-profile-points')}:</label>
            <span>{profile.user.points ? profile.user.points : "0"}</span>
          </div>
        ]
        : "" }
        <MenuIcon className={`${styles.profileMenuIcon}`} onClick={toggleMenu}/>
      </div>
    )
  }

  const [pageIndex, setPageIndex] = useState(1);

  const swapPage = (newValue) => {
    setPageIndex(newValue);
    setHideMenu(true);
  };

  return (
    <div className={`${styles.userDashboard}`}>
      <div className={`${styles.dashboardPage} ${hideMenu ? "" : styles.menuOpen}`}>
        <TopNav locale={locale} translations={translations} profile={profile} />
        {/* {pageIndex === 0 && <div>dashboard</div>} */}
        {pageIndex === 1 && <Profile key="tab-1" locale={locale} translations={translations} profile={profile} getUser={user} />}
        {pageIndex === 2 && <BigWins key="tab-2" locale={locale} translations={translations} profile={profile} games={gamesData} casinos={casinosData} getUser={user} />}
        {pageIndex === 3 && <Reviews key="tab-3" locale={locale} translations={translations} profile={profile} getUser={user} casinos={casinosData} />}
      </div>
      <div
        className={`${styles.userDashboardMenu} ${hideMenu ? "" : styles.menuOpen}`}
        >
        <div className={`${styles.dashboardLogoWrapper}`}>
          <Link to={r.get(locale, translations, "home", "")} className={`${styles.dashboardLogo}`}>
            <img src="../../icons/tv_set@3x.png" alt={`Mr. Gamble`}/>
          </Link>
          {hideMenu ? "" : <CloseIcon className={`${styles.profileCloseIcon}`} onClick={toggleMenu}/>}
        </div>
        <Divider />
        <ul className={`${styles.mainMenu}`}>
          {/* <li onClick={() => swapPage(0)} className={`${pageIndex === 0 && styles.isActive}`} id={`simple-tab-0`} aria-controls={`simple-tabpanel-0`}>
            <HomeOutlinedIcon/>
            <span>dashboard</span>
          </li> */}
          <li onClick={() => swapPage(1)} className={`${pageIndex === 1 && styles.isActive}`} id={`simple-tab-1`} aria-controls={`simple-tabpanel-1`}>
            <PersonOutlineOutlinedIcon/>
            <span>{t.get(translations, 'myaccount-profile-menu')}</span>
          </li>
          <li onClick={() => swapPage(2)} className={`${pageIndex === 2 && styles.isActive}`} id={`simple-tab-3`} aria-controls={`simple-tabpanel-3`}>
            <CasinoOutlinedIcon/>
            <span>{t.get(translations, 'myaccount-big-wins-menu')}</span>
          </li>
          <li onClick={() => swapPage(3)} className={`${pageIndex === 3 && styles.isActive}`} id={`simple-tab-2`} aria-controls={`simple-tabpanel-2`}>
            <RateReviewOutlinedIcon/>
            <span>{t.get(translations, 'myaccount-reviews-menu')}</span>
          </li>
        </ul>
        <Divider />
        <ul className={`${styles.actionMenu}`}>
          <li onClick={handleLogout}>
            <ExitToAppOutlinedIcon />
            <span>{t.get(translations, "login-logout")}</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default localize(MyAccountPage)
export const query = graphql`
  query MyAccountPage {
    allSanityCasino {
      edges {
        node {
          name
          _id
          id
        }
      }
    }
    allSanityGame {
      edges {
        node {
          name
        }
      }
    }
  }
`
