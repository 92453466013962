import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useContext } from "react"
import styles from "./profile.module.scss"
import r from "../../../services/Router"
import t from "../../../services/GetTranslationValue"
import ThemeContext from "../../../context/ThemeContext"
import PersonIcon from '@material-ui/icons/Person';
import Skeleton from '@material-ui/lab/Skeleton';
import EmailIcon from '@material-ui/icons/Email';
import { isLoggedIn, getUser } from "../../../services/auth";
import { API_ROOT } from "gatsby-env-variables"
import { getLocaleAlias } from "../../../graphql-assistant/createLocaleTextGetter"

const Profile = ({ locale, translations, profile, getUser }) => {

  const emailLabel = t.get(translations, "email")
  const noOfPointsLabel = t.get(translations, "number-of-points")
  // const noProfilePoints = t.get(translations, "no-profile-points")
  const twitchAccountLabel = t.get(translations, "twitch-account")

  return (
    <div>
      { isLoggedIn() && profile && profile.user ? (
        <div className={`${styles.myAccountPage}`}>
          <div className={`${styles.userInfo}`}>
            <div className={`${styles.nameRow}`}>
              <PersonIcon className={`${styles.profilePic}`} />
              <p className={`${styles.userName}`}>{profile.user.username}</p>
            </div>
            <p className={`${styles.aboutRow}`}>
              <label>{t.get(translations, 'user-profile-user-id')}: </label>
              <span>{profile.user.guid}</span>
            </p>
            <p className={`${styles.aboutRow}`}>
              <label>{t.get(translations, 'user-profile-joined-date')}: </label>
              <span>{profile.user.createdAt.slice(0, 10)}</span>
            </p>
            <p className={`${styles.aboutRow}`}>
              <label>{emailLabel}: </label>
              <span>{profile.user.email === 'info@kasinokeisarin.com' ? 'mrgamble@mr-gamble.com' : profile.user.email}</span>
              <span>{profile.user.isVerified ? 'Verified' : 'Not verified'}</span>
            </p>
            {profile.user.twitchId ? (
              <p className={`${styles.aboutRow}`}>
                <label>{twitchAccountLabel}: </label>
                <span>
                  <a href={`https://twitch.tv/${profile.user.username}`}>https://twitch.tv/{profile.user.username}</a>
                </span>
              </p>
            ) : (
              <p className={`${styles.aboutRow}`}>
                <label>{twitchAccountLabel}: </label>
                <span>
                  <a href={`${API_ROOT}/auth/twitch/login/${getLocaleAlias(locale)}/${t.get(translations, "My Account Url")}`}>{t.get(translations, 'connect-twitch-account')}</a>
                </span>
              </p>
            )}
            <hr/>
            <p className={`${styles.pointsRow}`}>
              <label>{noOfPointsLabel} : </label>
              <span>{profile.user.points ? profile.user.points : "0"}</span>
            </p>
          </div>

        </div>)
        :
        <div className={`${styles.myAccountPage}`}>
          <div className={`${styles.userInfo} ${styles.userProfileSkeleton}`}>
            <Skeleton className={`${styles.skeletons}`} variant="circle" width={130} height={130} animation="wave" />
            <Skeleton className={`${styles.skeletons}`} variant="text" animation="wave" width="60%" />
            <Skeleton className={`${styles.skeletons}`} variant="text" animation="wave" width="80%" />
            <Skeleton className={`${styles.skeletons} ${styles.skeletonBody}`} variant="rect" width={440} height={200} animation="wave" />
          </div>
        </div>
      }
    </div>
  )
}
export default Profile
