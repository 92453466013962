import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useContext, useState, useEffect } from "react"
import styles from "./big-wins.module.scss"
import r from "../../../services/Router"
import t from "../../../services/GetTranslationValue"
import ThemeContext from "../../../context/ThemeContext"
import { fetchMedias, fetchAdminMedias } from "../../../api/client/media"
import { Stream } from "@cloudflare/stream-react";
import { Button, Card, CardActionArea, CardMedia } from "@material-ui/core"
import { getLocaleAlias } from "../../../graphql-assistant/createLocaleTextGetter"
import MediaUploader from '../../../components/media-upload/index';

const BigWins = ({ locale, translations, profile, games, casinos, getUser }) => {

  const [uploadOpen, setUploadOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [errors, setErrors] = useState({});
  const [currentMedia, setCurrentMedia] = useState("");

  const videosMainTitle = t.get(translations, 'user-profile-all-uploaded-bigwin-medias')
  const videoTtile = t.get(translations, 'user-profile-media-title')
  const videoLocale = t.get(translations, 'user-profile-media-locale')
  const videoCasino = t.get(translations, 'user-profile-media-casino')
  const videoGame = t.get(translations, 'user-profile-media-game')

  const toggleUploadPopup = () => {
    setUploadOpen(!uploadOpen);
  };

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal
    const func = async () => {
      try {
        await profile
        const res = await fetchAdminMedias('both', signal)
        setMedias(res.medias)
      }
      catch(err) {
        setErrors(err)
      }
      let isAdvancedUpload = function () {
        let div = document.createElement('div');
        return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
      }();
    }
    func()
    return function cleanup() {
      abortController.abort()
    }
  }, [])

  const mediaUploadClose = () => {
    setUploadOpen(false);
  }

  const videoUpload = () => async () => {

    const signal = (new AbortController()).signal
    fetchAdminMedias('both', signal)
      .then(res => setMedias(res.medias))
    toggleUploadPopup()

  }

  const UploadedVideos = ({ locale, translations, options, title, value, setValue, size }) => {
    return (
      <div className={`${styles.uploadedVideos}`}>
        {medias ? [
          <h3 key={'header'}>{videosMainTitle}:</h3>,
          <div key={'container'} className={`${styles.videosWrapper}`}>
            {medias && medias.map(media => media.extMediaId ? (
              <div key={`${media.extMediaId}-${media.id}`} className={`${styles.mediaItem}`}>
                {media.status ? 
                  <Link to={media.status ? `/${getLocaleAlias(locale)}/${t.get(translations, 'big-wins')}/w/${media.title && media.title.toLowerCase().replace(/\s/g, "-") + '-' + media.id}/` : '#'}>
                    {/* state={{ modal: true, item: x, index: index }} */}
                    <div className={`profile-uploaded-videos ${styles.mediaDetailThumb}`}>
                      {media.type === "video" ?
                        <Stream
                          controls
                          src={media.extMediaId}
                          poster={`https://videodelivery.net/${media.extMediaId}/thumbnails/thumbnail.jpg?time=1s&height=220`}
                        />
                        :
                        <img src={media.url} alt={media.title} />
                      }
                    </div>
                  </Link>
                  :
                  <div className={`profile-uploaded-videos ${styles.mediaDetailThumb} ${styles.moderationThumb}`}>
                    <span className={`${styles.moderation}`}>{t.get(translations, 'user-profile-awaiting-moderation')}</span>
                  </div>
                }
                <div className={`${styles.mediaDetailRow}`}>
                  <label>{videoTtile}: </label>
                  <span>{media.title}</span>
                </div>
                <div className={`${styles.mediaDetailRow}`}>
                  <label>{videoLocale}: </label>
                  <span>{media.locale}</span>
                </div>
                <div className={`${styles.mediaDetailRow}`}>
                  <label>{videoCasino}: </label>
                  <span>{media.casinoId}</span>
                </div>
                <div className={`${styles.mediaDetailRow}`}>
                  <label>{videoGame}: </label>
                  <span>{media.gameId}</span>
                </div>
              </div>
            ) : 'null')}
            {currentMedia && currentMedia.type == 'video' ? (
              <Stream
                controls
                src={currentMedia}
              />
            ) : null}
          </div>
         ] : "No media uploaded yet!"
        }
      </div>
    )
  }

  return [
    <div className={`${styles.uploadWinsBox}`}>
      <div className={`${styles.shade}`}></div>
      <p>{t.get(translations, 'user-profile-share-your-big-win-moments-and-earn-points')}!</p>
      <Button variant="contained" color="primary" disableElevation onClick={toggleUploadPopup}>
        {t.get(translations, 'user-profile-upload')}
      </Button>
      <Link
        className={`${styles.navItem}`}
        to={`/${getLocaleAlias(locale)}/${t.get(translations, 'big-wins')}/`}
      >
        <Button variant="contained" color="secondary" disableElevation>
          {t.get(translations, 'user-profile-see-other-players-bigwins')}
        </Button>
      </Link>
    </div>,
    <MediaUploader isOpen={uploadOpen} getUser={getUser} mediaUploadClose={mediaUploadClose} videoUpload={videoUpload()} />,
    <UploadedVideos translations={translations} />
  ]
}
export default BigWins
